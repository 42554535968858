import "./styles.scss"

import React from "react"

const Publications = ({ data }) => {
  const { pubDesc, pubImage, pubLink, pubTitle } = data

  return (
    <section className="why-us-publications">
      <div className="container">
        <div className="flex-column-reverse flex-lg-row why-us-publications__row">
          <div className="col-lg-6 col-12">
            <div className="why-us-publications__content">
              <h2>{pubTitle}</h2>
              <div dangerouslySetInnerHTML={{ __html: pubDesc }} />

              <a
                href={pubLink}
                target="_blank"
                rel="noopener noreferrer"
                className="why-us-publications__cta"
              >
                Dowiedz się więcej
              </a>
            </div>
          </div>
          <div className="offset-sm-3 col-sm-6 offset-lg-0 col-12">
            <div className="why-us-publications__img">
              <img src={pubImage?.sourceUrl} alt={pubImage?.altText} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Publications
