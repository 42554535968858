import "./styles.scss"

import React from "react"

const Features = ({ tails }) => {
  return (
    <section className="why-us-features">
      <div className="container">
        <div className="row">
          {tails?.map(({ tailTitle, tailText, tailIcon }, index) => {
            return (
              <div key={index} className="col-12 col-sm-6 col-md-4">
                <div className="why-us-features__item">
                  <div className="why-us-features__icon">
                    <img src={tailIcon?.sourceUrl} alt={tailIcon?.altText} />
                  </div>
                  <p className="why-us-features__title">{tailTitle}</p>
                  <p className="why-us-features__text">{tailText}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Features
