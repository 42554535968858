import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import TestimonialsSlider from "components/TestimonialsSlider"
import {
  Features,
  Publications,
  Certificates,
  Recommendations,
} from "page_components/why-us"

const WhyUs = ({ data }) => {
  const breadcrumbs_data = [
    {
      name: "Dlaczego my",
      href: "/dlaczego-my",
    },
  ]
  const { certificates, recommendations, singleTail } =
    data?.allWpPage?.nodes?.[0]?.acfWhyUs

  return (
    <Layout
      seo={{
        title: "Dlaczego my",
        description:
          "Medica per pedes to jedyny tak specjalistyczny Gabinet na Podhalu! Ponad 20 lat doświadczenia.",
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Dlaczego my" />
      <Features tails={singleTail} />
      <Publications data={data?.allWpPage?.nodes?.[0]?.acfWhyUs} />
      <Certificates certificates={certificates} />
      <Recommendations recommendations={recommendations} />
      {/* <OurClients clients={data?.allWpClient?.nodes} /> */}
      <TestimonialsSlider
        title="PRZEKONAJ SIĘ DLACZEGO WARTO NAM ZAUFAĆ"
        show_more_btn={false}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDoyNzY=" } }) {
      nodes {
        id
        acfWhyUs {
          recommendations {
            altText
            sourceUrl
          }
          certificates {
            altText
            sourceUrl
          }
          pubLink
          pubDesc
          pubTitle
          pubImage {
            altText
            sourceUrl
          }
          singleTail {
            tailText
            tailTitle
            tailIcon {
              altText
              sourceUrl
            }
          }
        }
      }
    }
    allWpClient {
      nodes {
        acfClients {
          logo {
            altText
            sourceUrl
          }
        }
        id
      }
    }
  }
`

export default WhyUs
