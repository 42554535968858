import "./styles.scss"
import "react-image-lightbox/style.css"

import React from "react"
import { useState } from "react"

import Button from "components/Button"
import GalleryModal from "../GalleryModal"

const Certificates = ({ certificates }) => {
  const [fullListOpen, setFullListOpen] = useState(false)
  const [certificatesModal, setCertificatesModal] = useState(null)

  const short_list = certificates?.slice(0, 7)

  const current_list = fullListOpen ? certificates : short_list
  return (
    <section className="why-us-certificates">
      <div className="container">
        <h2>Certyfikaty</h2>
        <div className="row">
          {current_list?.map((item, index) => {
            if (index < 4) {
              return (
                <div key={index} className="col-lg-3 col-sm-6 col-12">
                  <button
                    type="button"
                    className="why-us-certificates__first-row"
                    onClick={() => {
                      setCertificatesModal(index + 1)
                    }}
                  >
                    <img src={item.sourceUrl} alt={item.altText} />
                    <div className="why-us-certificates__overlay">
                      <img
                        src={require(`assets/icons/lens.svg`).default}
                        alt=""
                      />
                    </div>
                  </button>
                </div>
              )
            } else {
              return (
                <div key={index} className="col-lg-4 col-sm-6 col-12">
                  <button
                    type="button"
                    className="why-us-certificates__items"
                    onClick={() => {
                      setCertificatesModal(index + 1)
                    }}
                  >
                    <img src={item.sourceUrl} alt={item.altText} />
                    <div className="why-us-certificates__overlay">
                      <img
                        src={require(`assets/icons/lens.svg`).default}
                        alt=""
                      />
                    </div>
                  </button>
                </div>
              )
            }
          })}
        </div>
        {certificates.length > 7 ? (
          <Button
            type="button"
            className="button button-dark-green"
            onClick={() => setFullListOpen(!fullListOpen)}
          >
            {fullListOpen ? "Pokaż mniej" : "Pokaż więcej certyfikatów"}
          </Button>
        ) : null}
      </div>
      {certificatesModal && (
        <GalleryModal
          galleryItems={current_list}
          handleClose={() => setCertificatesModal(null)}
          first_slide_index={certificatesModal - 1}
        />
      )}
    </section>
  )
}

export default Certificates
