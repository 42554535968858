import "./styles.scss"

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper"

import Button from "components/Button"
import SliderArrow from "components/Icons/SliderArrow/slider-arrow"
import TestimonialTail from "../TestimonialTail"

const TestimonialsSlider = ({ title, show_more_btn }) => {
  SwiperCore.use([Autoplay, Navigation, Pagination])

  const data = useStaticQuery(graphql`
    query TestimonialsSliderQuery {
      allWpTestimonial(limit: 10, sort: { fields: date, order: DESC }) {
        nodes {
          acfTestimonials {
            date(locale: "pl")
            name
            review
            score
            source
            screenshot {
              altText
              sourceUrl
            }
          }
          id
          slug
        }
      }
    }
  `)

  const testimonialsSliderSettings = {
    loop: false,
    autoplay: {
      delay: 5000,
    },
    slidesPerView: 1,
    centeredSlides: false,
    speed: 700,
    scrollbar: {
      draggable: true,
    },
    spaceBetween: 30,
    pagination: {
      clickable: true,
      bulletClass: `home-testimonials__slider-bullets`,
    },
    navigation: {
      nextEl: ".home-testimonials__slider-arrow--next",
      prevEl: ".home-testimonials__slider-arrow--prev",
    },
    breakpoints: {
      992: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
    },
  }

  return (
    <section className="home-testimonials">
      <div className="container">
        {title !== "" ? (
          <p className="home-testimonials__title">{title}</p>
        ) : null}
        <h2 className="home-testimonials__heading">Opinie naszych pacjentów</h2>
        <div className="home-testimonials__slider">
          <Swiper {...testimonialsSliderSettings}>
            {data?.allWpTestimonial?.nodes?.map(
              ({ slug, acfTestimonials }, index) => {
                return (
                  <SwiperSlide key={index}>
                    <TestimonialTail
                      testimonial={acfTestimonials}
                      slug={slug}
                    />
                  </SwiperSlide>
                )
              }
            )}
            <div className="home-testimonials__nav">
              <div className="home-testimonials__slider-arrows">
                <div className="home-testimonials__slider-arrow home-testimonials__slider-arrow--prev">
                  <SliderArrow />
                </div>
                <div className="home-testimonials__slider-arrow home-testimonials__slider-arrow--next">
                  <SliderArrow />
                </div>
              </div>
            </div>
          </Swiper>
        </div>
        {show_more_btn ? (
          <Button to="/opinie/" className="button-dark-green" type="button">
            Zobacz wszystkie opinie
          </Button>
        ) : null}
      </div>
    </section>
  )
}

export default TestimonialsSlider
