import "./styles.scss"

import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation } from "swiper"

import Modal from "components/Modal"
import SliderArrow from "components/Icons/SliderArrow/slider-arrow"

const GalleryModal = ({ handleClose, galleryItems, first_slide_index }) => {
  SwiperCore.use([Navigation])

  const gallerySliderSettings = {
    loop: false,
    autoplay: false,
    slidesPerView: 1,
    centeredSlides: false,
    speed: 700,
    scrollbar: {
      draggable: true,
    },
    spaceBetween: 30,
    pagination: false,
    navigation: {
      nextEl: ".gallery-modal__slider-arrow--next",
      prevEl: ".gallery-modal__slider-arrow--prev",
    },
    initialSlide: first_slide_index,
  }

  return (
    <section className="gallery-modal">
      <Modal handleClose={handleClose}>
        <div className="gallery-modal__slider">
          <Swiper {...gallerySliderSettings}>
            {galleryItems?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="gallery-modal__img">
                    <img src={item.sourceUrl} alt={item.altText} />
                  </div>
                </SwiperSlide>
              )
            })}
            <div className="gallery-modal__nav">
              <div className="gallery-modal__slider-arrows">
                <div className="gallery-modal__slider-arrow gallery-modal__slider-arrow--prev">
                  <SliderArrow />
                </div>
                <div className="gallery-modal__slider-arrow gallery-modal__slider-arrow--next">
                  <SliderArrow />
                </div>
              </div>
            </div>
          </Swiper>
        </div>
      </Modal>
    </section>
  )
}

export default GalleryModal
