import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

const TestimonialTail = ({ testimonial, slug }) => {
  const { name, review, source } = testimonial

  const getSourceLogo = source => {
    switch (source) {
      case "Google":
        return (
          <img
            src={require("assets/icons/testimonials/google.svg").default}
            alt=""
          />
        )
      case "Booksy":
        return (
          <img
            src={require("assets/icons/testimonials/booksy.svg").default}
            alt=""
          />
        )
      case "Facebook":
        return (
          <img
            src={require("assets/icons/testimonials/facebook.svg").default}
            alt=""
          />
        )
      default:
        return null
    }
  }

  const source_logo = getSourceLogo(source)

  return (
    <Link to={`/opinie/${slug}/`}>
      <div className="testimonial-tail">
        <p className="testimonial-tail__client">{name}</p>
        <p className="testimonial-tail__text">{review}</p>
        <div className="testimonial-tail__desc">
          <div className="testimonial-tail__desc--left">{source_logo}</div>
          <div className="testimonial-tail__desc--right">
            <p className="testimonial-tail__link">Wyświetl szczegóły</p>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default TestimonialTail
